<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App'
}

</script>
<script type="text/javascript">
function fontSize(){
  var deviceWidth=document.documentElement.clientWidth>760?760:document.documentElement.clientWidth;
  document.documentElement.style.fontSize=(deviceWidth/76)+"px";
}
fontSize();
window.onresize=fontSize;
</script>

<style lang="scss">

</style>
