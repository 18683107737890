import Vue from "vue";
import axios from "axios";
import {Message} from "element-ui";
// 响应时间
// axios.defaults.timeout = 5 * 1000
// 配置cookie
// axios.defaults.withCredentials = true
// 配置请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 静态资源
Vue.prototype.$static = "";

// 配置接口地址
// axios.defaults.baseURL = 'http://test.zus.iirii.com:8000';
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
// axios.defaults.baseURL = 'https://oapi.aukoo.cn';

let loadingInstance;
// POST传参序列化(添加请求拦截器)

axios.interceptors.request.use(
    config => {
        // loadingInstance = Loading.service({
        //     lock: true,
        //     text: '数据加载中，请稍后...',
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.7)'
        // })
        // loadingInstance;
        if (config.method === "post") {
            config.data = config.data;
        }
        return config;
    },
    err => {
        // loadingInstance.close()
        Message.error("请求错误");
        return Promise.reject(err);
    }
);
// 返回状态判断(添加响应拦截器)

axios.interceptors.response.use(
    res => {
        if (res.data.code == 200) {
            // 数据获取成功
        } else if (res.data.code == 401) {
            Message.error(res.data.msg);
            localStorage.clear();
            location.href = "/login";
        } else if (res.data.code == 402) {
            Message.error(res.data.msg);
            location.href = "/unauthorized";
        } else if (res.data.code == 30201) {
            // 等于30201什么都不提示

        }
        else {
            Message.error(res.data.msg);
        }
        return res.data;
    },
    err => {
        if (err.response.status == 401) {
            localStorage.clear();
            setTimeout(() => location.href = "/login", 300);
        } else {
            Message.error(err.response.status + " :网络或服务异常，请稍后重试");
        }
        return Promise.reject(err.response);
    }
);

// 发送请求 Get
export function get(url, params) {
    return new Promise((resolve, reject) => {
        params ? url = url + '?' + params : url;
        axios
            .get(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

// 发送请求 Post
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded;",
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            )
            .catch(err => {
                reject(err);
            });
    });
}

export function postFormData(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            )
            .catch(err => {
                reject(err);
            });
    });
}

// 发送请求 Delete
export function del(url) {
    return new Promise((resolve, reject) => {
        axios
            .delete(url, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
}

// 发送请求 Put
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .put(url, params, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token")
                }
            })
            .then(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            )
            .catch(err => {
                reject(err);
            });
    });
}

//阿里云专用
export function putaliyun(url, params) {
    return new Promise((resolve, reject) => {
        axios
            .put(url, params, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            )
            .catch(err => {
                reject(err);
            });
    });
}

// 文件postFile请求
export function postFile(url, params, headers) {
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, headers)
            .then(
                res => {
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            )
            .catch(err => {
                reject(err);
            });
    });
}
