<template>
<div v-if="isExternal" :style="styleExternalIcon" class="svg-external-icon svg-icon" v-on="$listeners"/>
<svg v-else :class="svgClass" :style="{ width: `${size.width}px`, height: `${size.height}px` }" aria-hidden="true"
     v-on="$listeners">
	<use :href="iconName"/>
</svg>
</template>

<script>
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage
import {isExternal} from "@/utils/validate";

export default {
	name: "SvgIcon",
	props: {
		iconClass: {
			type: String,
			required: true,
		},
		className: {
			type: String,
			default: "",
		},
		size: {
			type: Object,
			default: {
				width: 16,
				height: 16,
			}
		},
	},
	computed: {
		isExternal() {
			return isExternal(this.iconClass);
		},
		iconName() {
			return `#icon-${this.iconClass}`;
		},
		svgClass() {
			if (this.className) {
				return "svg-icon " + this.className;
			} else {
				return "svg-icon";
			}
		},
		styleExternalIcon() {
			return {
				mask: `url(${this.iconClass}) no-repeat 50% 50%`,
				"-webkit-mask": `url(${this.iconClass}) no-repeat 50% 50%`,
			};
		},
	},
};
</script>

<style scoped>
.svg-icon {
	vertical-align: sub;
	/*  fill: #9DA5B0;*/
	fill: #FFF;
	overflow: hidden;
}

.svg-external-icon {
	background-color: #FFF;
	mask-size: cover !important;
	display: inline-block;
}
</style>
