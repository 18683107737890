export default function authorityButton(msg){
    let isShow=false;
    let nowBtList=JSON.parse(window.localStorage.getItem('btnList'));
    nowBtList.forEach(element => {
        if(element.name===msg){
            isShow=true;
        }
    });
    return isShow;
}
