import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

let _this = Vue.prototype;

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        count: 0,
        username: "",
        userInfos: {},
        routerList: {},
        routerID:0,
        isFold:true,
        isHidden:true,
        isReset:"",
        setBtnList: [],
        setMenuList:[]
    },
    getters: {
        getUserInfo(state) {
            return state.userInfos;
        },
        getRouterlist(state) {
            return state.routerList;
        },
        getIsFold(state) {
            return state.isFold;
        }
    },
    mutations: {
        mutationsGetUserinfo(state, userinfo) {
            state.username = userinfo.username;
            state.userInfos = userinfo;
        },
        mutationsGetUserRouter(state, routerList) {
            state.routerList = routerList;
        },
        getmenuPath(state,id) {
            state.routerID = id;
        },
        //
        mutationsIsFold(state) {
            state.isFold = !state.isFold;
        },
        mutationsIsHidden(state) {
            state.isHidden = !state.isHidden;
        },
        personalInformation(state, personalInfo) {

            state.personalInfo = personalInfo;
            state.isReset=personalInfo.isReset
        },
        //设置按钮权限
        SET_AUTH_BUTTON: (state, data) => {  // 设置授权按钮权限
            let btnList = [];
            try {
                var setAuthButton=function(valChildren){
                    valChildren.forEach(v =>{
                        if(v.menuType === 2){
                            btnList.push({
                                name: v.name,
                                id:v.id
                            })
                        }
                        if(v.children){
                            setAuthButton(v.children);
                        }
                    });
                }
                setAuthButton(data)
                state.setBtnList = btnList;
                console.log(btnList);
                window.localStorage.setItem('btnList',JSON.stringify(btnList));
            }catch (e) {
                _this.$message('当前暂无权限信息，请联系管理员配置');
            }

        },
        //设置菜单权限
        SET_AUTH_MENUS:(state,data)=>{
            state.setMenuList = data
            localStorage.setItem(
                "menuList",
                JSON.stringify(data)
            );

        }

    },
    actions: {
        //获取个人信息
        obtainingPersonalInformation({commit}) {
            _this.$api.user_api.personalInformation({}).then(res => {
                if (res && res.data) {
                    commit("personalInformation", res.data);
                    localStorage.setItem('personalInformation', JSON.stringify(res.data));
                }
            });
        },
        //获取用户信息
        actionsGetUserinfo({commit}) {
            commit("mutationsGetUserinfo", JSON.parse(localStorage.getItem("userInfos")));
        },
        //获取权限菜单
        actionsGetAuthMenus({commit},data) {
            commit('SET_AUTH_MENUS',data)

        },
        //获取权限按钮
        actionsGetAuthButtons({commit},data) {
            commit('SET_AUTH_BUTTON',data)
        },
        //缓存历史记录
        getmenuPath({commit},data) {
            commit('getmenuPath', data.id)
           router.push({path: data.path});
            //保存历史记录
            let historyPath = {
                path: data.path,
                id: data.id
            }
            window.localStorage.setItem('historyPath', JSON.stringify(historyPath))
        },
        //全局折叠开关
        actionsIsFold({commit}) {
            commit("mutationsIsFold");
        },
        //全局头部开关
        actionsIsHidden({commit}) {
            commit("mutationsIsHidden");
        },
        //退出登录
        actionsLogOut() {
            console.log("actions")
            _this.$api.login_api.logout({}).then((res) => {
                if (res.code == 200) {
                    _this.$message({message: '注销成功', type: 'success'});
                    localStorage.clear();
                    location.href = "/login";
                }
            })
        }
        //设置权限按钮

    },
    modules: {},
})
