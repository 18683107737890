import { post , get , put, del} from '../axiosconfig'

import qs from 'qs';
export default {

  // 登录
  login(params) {
    return post('/zsc/distributor/login', params)
  },
  // 退出登录
  logout(params) {
    return post('/zsc/distributor/logout', params)
  },
  // 修改密码
  changePassword(params) {
    return post('/zsc/distributor/modify_password', params)
  },


}
